export default {
  name: { defaultValue: '' },
  address: { defaultValue: '' },
  city: { defaultValue: '' },
  state: { defaultValue: '' },
  country: { defaultValue: '' },
  zip: { defaultValue: '' },
  email: { defaultValue: '' },
  phone: { defaultValue: '' },
  is_active: { defaultValue: true }
}
